.comment-timeline {
  .comment-timeline-item {
    --bg-color: #f9f0ff;
    --color: #d3adf7;

    color: var(--color);

    &[data-status='PLANNING'] {
      --bg-color: #e6f4ff;
      --color: #69b1ff;
    }

    &[data-status='IN_REVIEW'] {
      --bg-color: #fffbe6;
      --color: #ffd666;
    }

    &[data-status='APPROVED'] {
      --bg-color: #f6ffed;
      --color: #b7eb8f;
    }

    &[data-status='REJECTED'] {
      --bg-color: #fff1f0;
      --color: #ff7875;
    }

    .comment-timeline-item-icon {
      background-color: var(--bg-color);
      border-color: var(--color);
    }
  }
}

.class-timeline .ant-timeline-item:not(:last-child) {
  margin-bottom: 35px; /* Her timeline arasındaki mesafe */
}

.class-timeline .ant-timeline-item-tail:not(:last-child) {
  height: calc(100% + 35px); /*Timeline arasındaki çizginin uzunluğu */
}

div.timeline_container div.ant-timeline-item-label {
  width: calc(20% - 12px) !important; /* Timeline sol tarafındaki yazı -- Sağa yanaştırmak için 20%'yi arttır */
}

div.timeline_container div.ant-timeline-item-content {
  left: calc(21% - 4px) !important; /* Timeline sağ tarafındaki yazı -- Sağa yaklaştırmak için left'in %21 artır width'in %77 aynı oranda azalt  */
  width: calc(67% - 4px) !important;
}

div.timeline_container div.ant-timeline-item-tail,
div.ant-timeline-item-head {
  left: 21% !important; /* Sağa yanaştırmak için %21'i arttırılabilir.  */
}

/*------------------------------------------------ */
/*TEACHER CLASS AKIŞ / HABERLER Timeline component */

.flow-timeline .ant-timeline-item:not(:last-child) {
  margin-bottom: 15px; /* Her timeline arasındaki mesafe */
}

.flow-timeline .ant-timeline-item-tail:not(:last-child) {
  height: calc(100% + 15px); /*Timeline arasındaki çizginin uzunluğu */
}

div.flow_container div.ant-timeline-item-label {
  width: calc(25% - 12px) !important; /* Timeline sol tarafındaki yazı -- Sağa yanaştırmak için 20%'yi arttır */
}

div.flow_container div.ant-timeline-item-content {
  left: calc(26% - 4px) !important; /* Timeline sağ tarafındaki yazı -- Sağa yaklaştırmak için left'in %21 artır width'in %77 aynı oranda azalt  */
  width: calc(67% - 4px) !important;
}

div.flow_container div.ant-timeline-item-tail,
div.flow_container div.ant-timeline-item-head {
  left: 26% !important; /* Sağa yanaştırmak için %21'i arttırılabilir.  */
}

/*STUDENT PROFILE TABS */

.student-flow-timeline .ant-timeline-item:not(:last-child) {
  margin-bottom: 50px; /* Her timeline arasındaki mesafe */
}

.student-flow-timeline .ant-timeline-item-tail:not(:last-child) {
  height: calc(100% + 24px); /*Timeline arasındaki çizginin uzunluğu */
}

div.student_flow_container div.ant-timeline-item-label {
  width: calc(10% - 12px) !important; /* Timeline sol tarafındaki yazı -- Sağa yanaştırmak için 20%'yi arttır */
}

div.student_flow_container div.ant-timeline-item-content {
  left: calc(11% - 4px) !important; /* Timeline sağ tarafındaki yazı -- Sağa yaklaştırmak için left'in %21 artır width'in %77 aynı oranda azalt  */
  width: calc(52% - 4px) !important;
}

div.student_flow_container div.ant-timeline-item-tail,
div.student_flow_container div.ant-timeline-item-head {
  left: 11% !important; /* Sağa yanaştırmak için %21'i arttırılabilir.  */
}
