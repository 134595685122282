.weekly {
  display: flex;
  flex-direction: column;
  background-color: #f5f5f5; /* Light background for the entire calendar */
}

.weekly-calendar {
  display: grid;
  grid-template-columns: 100px repeat(7, 1fr);
  gap: 1px;
  background-color: #e8e8e8;
}

.weekly-calendar .header {
  background-color: #fff;
  font-size: 16px; /* Adjust font size to match target */
  text-align: center;
  border-bottom: 1px solid #d9d9d9;
  padding: 8px 0; /* Added padding for better spacing */
  color: #000; /* Ensure text color is black for better readability */
}

.weekly-calendar .time-cell {
  background-color: #fff;
  font-weight: bold;
  height: 60px; /* Ensure this represents one hour */
  display: flex;
  align-items: center;
  justify-content: center;
  border-right: 1px solid #d9d9d9;
}

.weekly-calendar .time-slot {
  position: relative;
  height: 60px; /* Ensure this represents one hour */
  background-color: #fff;
  border-bottom: 1px solid #d9d9d9;
  border-right: 1px solid #d9d9d9;
}

.weekly-calendar .event {
  border-radius: 4px;

  box-sizing: border-box;
  position: absolute;
  border: 1px solid;
  display: flex;
  align-items: center; /* Vertically center */
  justify-content: center; /* Horizontally center */
  text-align: center;
  z-index: 1;
}

.weekly-calendar .event.success {
  background-color: #f6ffed;
  border-color: #b7eb8f;
  color: #52c41a;
}

.weekly-calendar .event.error {
  background-color: #fff1f0;
  border-color: #ffa39e;
  color: #ff4d4f;
}

/* Bugünkü günün mavi arka planını ve kenarlığını başka tarihlerde de gerçekleşme durumunu kaldırıyoruz */
.ant-picker-cell-selected:not(.ant-picker-cell-today) .ant-picker-cell-inner {
  background-color: transparent !important;
}

.ant-picker-cell-selected:not(.ant-picker-cell-today) .ant-picker-cell-inner:hover {
  background-color: rgba(0, 0, 0, 0.04) !important;
}

.ant-picker-cell-selected:not(.ant-picker-cell-today) .ant-picker-cell-inner .ant-picker-calendar-date-value {
  color: #000000e0 !important;
}
