.gantt {
  --item-heigth: 33px;
  --day: 10px;
  --day-offset: 0;
  --total-day: 0;

  display: flex;
  gap: 10px;

  .gannt-calendar {
    display: flex;
    flex-direction: column;
    position: relative;
    background-color: white;
    overflow-x: auto;
    overflow-y: auto;

    .gannt-calendar-header {
      display: flex;

      .gannt-calendar-header-item {
        --day-count: 30;
        --month: calc(var(--day-count) * var(--day));

        position: relative;
        width: var(--month);
        min-width: var(--month);
        max-width: var(--month);
        height: 40px;
        background-color: #1677ff;
        color: white;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    .gannt-calendar-row {
      --row-count: 1;
      position: relative;
      height: calc(var(--row-count) * var(--item-heigth) + 7);
      min-height: calc(var(--row-count) * var(--item-heigth) + 40px);
      border-bottom: 1px solid rgba(0, 0, 0, 0.06);

      width: 7610px;

      .gannt-calendar-item {
        --day-start: 0;
        --day-span: 0;
        --row-index: 0;

        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 12px;
        font-weight: 400;

        padding-left: 10px;
        padding-right: 2px;

        border-width: 1px;
        border-style: solid;
        border-radius: 5px;
        overflow: hidden;

        position: absolute;
        top: 7px;
        height: 26px;
        width: calc(var(--day) * var(--day-span));
        transform: translateX(calc(var(--day) * (var(--day-start) + var(--day-offset)))) translateY(calc(var(--item-heigth) * var(--row-index)));

        .ant-btn-icon {
          color: #69b1ff;
        }
      }

      .gantt-calendar-item-active {
        background-color: #f6ffed;
        border-color: #52c41a;
        color: #52c41a;
      }

      .gantt-calendar-item-planning {
        background-color: #fffbe6;
        border-color: #faad14;
        border-style: dashed;
        color: #faad14;
      }

      .gantt-calendar-item-canceled {
        background-color: #f5f5f5;
        border-color: #f5f5f5;
        color: #8b8b8b;
      }
      .gantt-calendar-item-done {
        background-color: #e6f4ff;
        border-color: #69b1ff;
        color: #69b1ff;
      }
      .gantt-calendar-item-action-required {
        background-color: #f9f0ff;
        border-color: #722ed1;
        color: #722ed1;
      }
      .gantt-calendar-item-delayed-action-required {
        background-color: #f9f0ff;
        border-color: #f9f0ff;
        color: #d3adf7;
      }
    }

    .gantt-calendar-divider {
      --start-day: 0;
      --left: calc(var(--start-day) * var(--day));

      position: absolute;
      width: 1px;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.06);

      left: var(--left);

      &.today {
        background-color: #722ed1;
        --left: calc((var(--start-day) + var(--day-offset)) * var(--day));
      }
    }
  }

  .gantt-top-header {
    height: 40px;
    min-width: 230px;
    padding-left: 15px;
    padding-right: 15px;
    background-color: #1677ff;
    color: white;
    display: flex;
    align-items: center;
  }

  .gannt-row-header {
    --row-count: 1;
    height: calc(var(--row-count) * var(--item-heigth) + 7px);
    min-height: calc(var(--row-count) * var(--item-heigth) + 40px);
    background-color: white;
    border-bottom: 1px solid rgba(0, 0, 0, 0.06);
    border-radius: 5px;
    padding-left: 15px;
    padding-right: 15px;
    display: flex;
    align-items: center;
  }
  .highlighted {
    background-color: rgb(249, 249, 249);
  }
}
