@import url('./planning.css');
@import url('./gannt-calendar.css');
@import url('./comment-timeline.css');
@import url('./header.css');
@import url('./weekly-calendar.css');
@import url('./student-tabs.css');

html,
body {
  margin: 0;
  padding: 0;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

pre {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;
}
.teacher-border .ant-tabs-ink-bar {
  background-color: #f9541c;
}

.ant-upload.ant-upload-select {
  width: 100%;
}

.ant-table-thead th {
  text-align: center !important;
}

:where(.css-dev-only-do-not-override-k83k30).ant-table-wrapper .ant-table.ant-table-small {
  font-size: 12px;
}

.ant-table-tbody tr:nth-child(even) {
  background-color: #f6f6f6;
}

table td,
table th {
  border-inline-end-color: #e3e3e3 !important;
  border-bottom-color: #e3e3e3 !important;

  border-inline-start-color: #e3e3e3 !important;
  border-top-color: #e3e3e3 !important;
}

.ant-table-body {
  overflow-y: auto !important;
}

:where(.css-dev-only-do-not-override-k83k30).ant-layout {
  background: #f8f8f8 !important;
}

.ant-table-container {
  border: 1px solid #d5d5d5 !important;
}

.help-right {
  .ant-form-item-explain {
    padding-top: 6px;
    text-align: right;
  }
}

.meta-avatar-padding {
  .ant-card-meta-avatar {
    padding: 4px;
  }
}

.ant-list-footer {
  border-top: 2px solid #ebebeb;
}

.calender-small-text {
  input {
    font-size: 12px !important;
  }
}

.exercise-message-container {
  width: 100%;

  .exercise-message {
    width: 100%;
    display: flex;
    align-items: end;
    gap: 4px;

    .exercise-message-text {
      width: 100%;
      background-color: #e6f4ff;
      border-radius: 8px;
      padding: 8px;
      font-size: large;
      border: 1px solid #1677ff;
    }

    .exercise-message-question {
      width: 100%;
      background-color: #ffffff;
      border-radius: 8px;
      padding: 8px;
      color: #722ed1;
      font-size: x-large;
      border: 1px dashed #1677ff;
      text-align: center;
    }
  }

  &[data-main-member='true'] {
    padding-left: 50px;

    .exercise-message {
      flex-direction: row-reverse;
      .exercise-message-text {
        border-bottom-right-radius: 0px;
      }
    }
  }

  &[data-main-member='false'] {
    padding-right: 50px;

    .exercise-message {
      flex-direction: row;

      .exercise-message-text {
        border-bottom-left-radius: 0px;
      }
    }
  }
}

.class-carousel .ant-carousel .slick-dots li button {
  background-color: blue; /* Dotların rengi */
}

.class-carousel .ant-carousel .slick-dots .slick-active button {
  background-color: blue; /* Aktif dot rengi */
}
