.plan-card-container {
  min-width: 350px;
}

.plan-card {
  width: 350px;
  --text-color: white;
  --icon-color: white;

  &[data-status='PLANNING'] {
    --bg-color: rgba(0, 0, 0, 0.04);
    --text-color: rgba(0, 0, 0, 0.25);
    --icon-color: rgba(0, 0, 0, 0.88);
  }

  &[data-status='IN_REVIEW'] {
    --bg-color: #1677ff;
  }

  &[data-status='APPROVED'] {
    --bg-color: #1677ff;
  }

  &[data-status='REJECTED'] {
    --bg-color: #ff4d4f;
  }

  .ant-card-head {
    background-color: var(--bg-color);
    color: var(--text-color);
    height: 29px;
    min-height: 29px;

    padding-right: 4px;

    .ant-btn {
      color: var(--icon-color);

      &:hover {
        color: var(--icon-color);
      }
    }

    .ant-card-head-title {
      font-size: 12px;
      line-height: 22px;
      font-weight: 400;
    }
  }

  .ant-card-body {
    padding: 0px 5px;

    .ant-tabs-nav {
      padding: 0px 10px;
    }
  }
}

.planblock {
  height: 34px;
  background-color: var(--bg-color);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 6px 4px;
  border-bottom: 1px solid var(--border-color);
  border-radius: 5px;

  --border-color: rgba(0, 0, 0, 0.06);
  --text-color: #1677ff;

  &[data-status='SUCCESS'] {
    --bg-color: #e6f4ff;
  }

  &[data-status='WARNING'] {
    --bg-color: #fff7e6;
    --text-color: #fa8c16;
  }

  &[data-status='ERROR'] {
    --bg-color: #fff1f0;
    --border-color: #ff4d4f;
    --text-color: #ff4d4f;
  }

  &.disabled {
    --bg-color: rgba(0, 0, 0, 0.04);
    --text-color: rgba(0, 0, 0, 0.25);
    --border-color: rgba(0, 0, 0, 0.06);

    span {
      color: rgba(0, 0, 0, 0.25);
    }
  }

  &.drag {
    background-color: white;
    border-radius: 0px;

    cursor: grab;

    &:active {
      cursor: grabbing;
    }

    .ant-btn-icon,
    .anticon {
      color: rgb(140, 140, 140);
    }
  }

  .ant-btn-icon {
    color: var(--text-color);
  }
}

.dropzone {
  display: flex;
  flex-direction: row;
  align-items: center;
  border: 1px dashed rgba(0, 0, 0, 0.06);
  border-radius: 5px;
  color: rgba(0, 0, 0, 0.25);
  gap: 10px;
  padding: 4px 8px;
  font-size: 10px;
  line-height: 20px;
  font-style: italic;
  user-select: none;

  .dropzone-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    font-size: 22px;
    background-color: rgba(0, 0, 0, 0.04);
    border: 1px dashed rgba(0, 0, 0, 0.06);
    border-radius: 1px;
  }
}

.plan-drag-tabs {
  .ant-tabs-nav-list {
    gap: 6px;
  }

  .ant-tabs-tab {
    margin: 0 !important;
  }
}

.plan-seance-hour {
  width: 100%;
  background-color: rgba(0, 0, 0, 0.04);
  border-bottom: 1px dashed rgba(0, 0, 0, 0.06);
  border-radius: 5px;
  font-size: 10px;
  line-height: 22px;
  padding: 0px 10px;
}

.planning-statistic-card {
  .ant-card-body {
    padding-top: 5px;
    padding-bottom: 5px;
  }

  .ant-statistic-title {
    font-size: 12px;
  }
}

.planning-filter {
  .ant-btn {
    display: flex;
    align-items: center;
    font-size: 10px;
  }
}

.plan-action-overlay {
  .ant-dropdown-menu-submenu-expand-icon {
    display: none;
  }
}

.custom-panel .ant-collapse-content-box {
  padding: 0 !important;
}

/*
Tab sekmelerinin altındaki çizgi oluşmasını engelliyor.
.ant-tabs-ink-bar {
  display: none !important;
} */
.ant-tabs-nav::before {
  border: none !important;
}

.fade-out {
  animation: fadeOut 2s forwards;
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
