.header-menu .ant-menu-item,
.header-menu .ant-menu-item-selected,
.header-menu .ant-menu-item-selected:focus,
.header-menu .ant-menu-item-selected:hover {
  display: inline-block;
  height: 44px;
  line-height: 44px;
  padding: 0 20px;
  margin: 0;
}

.header-menu .ant-menu-item-selected,
.header-menu .ant-menu-item-selected:focus,
.header-menu .ant-menu-item-selected:hover {
  overflow: hidden;
}

/* Teacher-Student Layout Menu border-bottom kaldırıyor */
.header-menu.ant-menu-horizontal > .ant-menu-item::after,
.header-menu.ant-menu-horizontal > .ant-menu-submenu::after {
  border-bottom: none !important;
  transition: none !important;
}
